import { Injectable, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Constants } from '../common/constants/lsnetx.constants';
import { HttpService } from './http.service'


import { OnlineStoreModel } from '../common/models/online-store-model';
import { HomePageService } from './home-page.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

/**
 * Initial Service when app is initialized.
 */
@Injectable()
export class StartUpService {
  /**
   * List of DI
   * @param platformId PLATFORM_ID
   * @param _http HttpClient
   * @param httpService HttpService
   * @param _document DOCUMENT
   * @param homePageService HomePageService
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public _http: HttpClient,
    public httpService: HttpService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public homePageService: HomePageService
  ) { }

  /**
   * when loading app, fetch settings from 'property-setting.json' file present in the assets.
   * Read settings from file and populate them in global constants.
   * If, storeId is present in the file and it is not '0', then it is single store else it will be multi-store.
   * For single store, fetch vendor details and resolve the promise.
   * For multi-store, fetch all the stores for siteID and resolve the promise.
   * In case of any error, rejec the promise with appropriate error.
   */
  load() {
    let promise = new Promise((resolve, reject) => { 
      this._http
         .get('assets/property-setting.json') 
        // .get('http://192.200.1.30:7121/assets/property-setting.json') 
        //"https://service.bamboogreens.in" 
        // .get('https://www.bookstime.in/assets/property-setting.json') //"https://service.bamboogreens.in" 

        // .get('http://192.200.1.205:4500/assets/property-setting.json') //local universal testing
        //  .get('https://www.bamboogreens.in/assets/property-setting.json') //bamboogreens live
        // .get('https://www.inalsaappliances.com/assets/property-setting.json') //inalsa live
        // .get('http://163.47.140.240:8280/assets/property-setting.json') //inalsa testing
        //  .get('http://163.47.140.240:8880/assets/property-setting.json') //bamboo testing
        .pipe(
          catchError((err): any => {
            console.log("Configuration file cannot be read");
            reject("Configuration file cannot be read");
          })).subscribe((data: any) => {
            Constants.SITE_ID = data.siteId;
            Constants.BASE_URL = data.baseUrl;
            Constants.STORE_ID = data.storeId;
            Constants.TIME_OUT = data.timeout;
            Constants.STORE_TYPE = data.storeType;
            Constants.COMPANY_ID = data.companyId;
            Constants.POPUP_ACTION = data.popupAction || false;
            Constants.CND_URL = data['cdn_url'];
            Constants.CND_UPLOAD = data['cdn_upload'];
            Constants.CND_DOWNLOAD = data['cdn_download'];
            /**
             * If not multi-store
             */
            if (data.storeId) {
              this.homePageService.getVendorDetails((resp) => {
                if (resp && !resp.error) {
                  resolve(true);
                }
                else if (resp.error) {
                  reject(resp)
                }
              });
            } else if (!(data.storeId) && data.companyId) { //multi-store
              this.homePageService.getAllStores((resp) => {
                if (resp && !resp.error) {
                  resolve(true);
                }
                else if (resp.error) {
                  reject(resp)
                }
              });
            } else {
              reject("Configuration file cannot be read");
            }
          });
    })
    return promise;
  }

}
