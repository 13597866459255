<footer *ngIf="!checkOutVisibility">
  <div class="defaultWidth">
    <div class="footer-mb tableDiv">
      <div class="account-info floatedLeft" *ngIf="loggedInUser">
        <p class="heading" id="lbl">MY ACCOUNT</p>
        <div>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Orders</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Transaction
            Logs</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change
            Password</a>
          <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
        </div>
      </div>
      <div *ngIf="quickLinks != undefined" class="quicklinks floatedLeft">
        <p class="heading" id="lbl">QUICKLINKS</p>
        <ul>
          <li *ngFor="let quickLink of quickLinks">
            <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
            <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
          </li>
        </ul>
      </div>
      <div class="storePnl floatedLeft">
        <p id="lbl">Stores</p>
        <ul>
          <li *ngFor="let category of categoryList">
            <a routerLink="collections/{{category?.categoryURL}}" class="lnkStores">{{category?.categoryDisplayName}}</a>
          </li>
        </ul>
      </div>
      <div class="contactDtlPnl floatedLeft">
        <p id="lbl">Call Us</p>
        <div>
          <p class="lblStores">{{vendorDetails.ccContactNo}}</p>
        </div>
      </div>
      <div class="social-share-container">
        <div *ngIf="socialSettings != undefined" class="social-share">
          <p id="lbl">Share it on</p>
          <div class="wrapper">
            <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"><span></span></a>
            <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"><span></span></a>
            <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin"
              title="LinkedIn"><span></span></a>
            <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span></span></a>
          </div>
        </div>
        <div class="newsletter-container">
            <p id="hdr">SUBSCRIBE TO OUR NEWSLETTER</p>
            <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
              <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail"
                #inputVal />
              <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)">
                <span>Send</span>
              </p>
              <span #error class="hide errorLbl"></span>
            </div>
          </div>
      </div>
      <!-- <div class="newsletter-container">
        <p id="hdr">SUBSCRIBE TO OUR NEWSLETTER</p>
        <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
          <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail"
            #inputVal />
          <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)">
            <span>Send</span>
          </p>
          <span #error class="hide errorLbl"></span>
        </div>
      </div> -->
    </div>
    <div class="copyright" fxLayout="row">
      <p class="copyright-txt" fxFlex="100">{{copyRTxt}}</p>
      <div class="power-by hide" fxLayout="row" fxLayoutAlign="end end" fxFlex="30">
        <p class="power-txt">Powered By</p>
        <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
            alt="LSNetX"></a>
      </div>
    </div>
  </div>
</footer>