<div class="bannerParent" *ngIf="images.length > 1">
    <ls-carousel [height]="bannerHeight" (clickAction)="doSomething($event)" width="100%" show-indicators="true"
        timeout="7000" [carousel-elements]="images"></ls-carousel>
    <!-- <ul>
        <li *ngFor="let image of images; let i = index;">
            <img [src]="(image?.imageUrl)?(image?.imageUrl): defaultBanner" title="{{image?.title}}" alt="{{image?.title}}" (error)="bannerImgErrorHandler($event)">
        </li>
    </ul> -->
</div>
<div class="bannerParent" *ngIf="images.length == 1">
    <div class="banner">
        <img [src]="(images[0]?.imageUrl)?(images[0]?.imageUrl): defaultBanner" title="{{images[0]?.title}}" alt="{{images[0]?.title}}"
            (error)="bannerImgErrorHandler($event)">
    </div>
</div>