<header *ngIf="!checkOutVisibility">
  <div fxLayout="column" class="desktop-layout">
    <div class="header-top">
      <div class="headerInner defaultWidth">
        <div id="logo">
          <a [routerLink]="''" style="height:100%; width:100%; position:relative;" *ngIf="companyDetails">
            <img [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath " onError="this.src = 'assets/images/defaultLogo.png'"
              title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
          </a>
        </div>
        <div class="heading-tag">
          <div *ngIf="currencyList != undefined" fxLayout="column" class="currency-combo">
            <div class="selected-div" (click)="showCurrencyList(currencyOption)">
              <span [id]="currencyList[selectedCurrencyIndex]?.currencyCode" class="currency-span"></span>
              <a class="currency-value"> {{currencyList[selectedCurrencyIndex]?.currencyCode}}</a>
            </div>
            <ul class="currency-option" #currencyOption>
              <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
                <span [id]="model.currencyCode" class="currency-span"></span>
                <a class="currency-value">{{model?.currencyCode}}</a>
              </li>
            </ul>
          </div>
          <div class="right-pnl">
            <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
              <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? userState.userName + '!!' : userState.fName + '!!'"></p>
              <span class="fa fa-caret-down"></span>
              <div id="dashOptionPnl" tabindex="1">
                <a routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
                <a routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
                <a routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Log</a>
                <a routerLink="my-dashboard/change-password" routerLinkActive="active">Change Password</a>
                <a routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist&nbsp;({{wishlistCount}})</a>
                <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a>
              </div>
            </div>
            <div id="trackDtls" *ngIf="!loggedIn">
              <p class="track-order" (click)="showTrackPopOver()">Track Order</p>
            </div>
            <div class="pnlCartOuter">
              <div class="cart" fxLayoutAlign="center center" (click)="openCart()" [ngClass]="{'hide': !allowCart}">
                <span class="fa fa-shopping-cart"></span>
                <div class="cartCntPnl">
                  <p class="cart-count">{{cartCount}}</p>
                </div>
              </div>
            </div>
            <div class="pnlSignInHdr">
              <div *ngIf="!loggedIn" fxLayoutAlign="center center" class="login" (click)="onOpen()">
                <a class="fa fa-user"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="headerInner defaultWidth">
        <div class="menuLinksPanel">
          <ul class="menuLinkItr">
            <li class="floatedLeft menuLink">
              <a [routerLink]="''" class="mainLink">Home</a>
            </li>
            <li class="floatedLeft menuLink" *ngIf="categoryList?.length == 1 && ( !categoryList[0]?.categoryModels || categoryList[0]?.categoryModels?.length == 0)">
              <a attr.categoryid={{categoryList[0]?.categoryId}} class="mainLink" routerLink="collections/{{categoryList[0]?.categoryURL}}">{{categoryList[0]?.categoryName}}</a>
            </li>
            <li class="floatedLeft menuLink" *ngIf="!(categoryList?.length == 1 && ( !categoryList[0]?.categoryModels || categoryList[0]?.categoryModels?.length == 0))">
              <div class="category">
                <div class="catMenuPnl categories">
                  <p id="catLbl">CATEGORIES
                    <span class="fa fa-caret-down"></span>
                  </p>
                </div>
                <div class="fetch-categories">
                  <ul *ngIf="categoryList != undefined" class="max-height">
                    <li *ngFor="let category of categoryList">
                      <div id="itrCat-0">
                        <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                          <h1>{{category?.categoryName}}</h1>
                        </a>
                        <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                          <li *ngFor="let subCat of category?.categoryModels">
                            <div id="itrCat-1">
                              <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                                style="position:relative">
                                {{subCat?.categoryName}}
                                <span *ngIf="subCat?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right"
                                  style="position: absolute;right: 9px;font-weight: bold;"></span>
                              </a>
                              <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                                <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                                  <div id="itrCat-2">
                                    <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                      routerLink="collections/{{secLevel?.categoryURL}}">
                                      {{secLevel?.categoryName}}
                                    </a>
                                    <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                                      <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                        <div id="itrCat-3">
                                          <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                            routerLink="collections/{{thirdLevel?.categoryURL}}">
                                            {{thirdLevel?.categoryName}}
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="floatedLeft collectionPanels menuLink" *ngFor="let collection of collectionArray; let par_index = index;">
              <a class="mainLink collection" routerLinkActive="active">{{collection?.collectionName}}
                <span class="fa fa-caret-down"></span>
              </a>
              <div class="gridShow">
                <div class="pnlProduct">
                  <ul class="gridItr">
                    <li class="floatedLeft" *ngFor="let productData of collection?.productModels;">
                      <div class="productTile">
                        <div class="productImagePnl">
                          <a id="lnkProduct" [routerLink]="goToProductDetailPage(productData)" [queryParams]="getQueryParamsForProduct(productData)">
                            <img id="productImage" [src]="(productData?.imageUrl && productData?.images && productData?.images[0])?productData.imageUrl+'m/'+productData?.images[0]:defaultPath "
                              alt="" (error)="imgErrorHandler($event)">
                          </a>
                        </div>
                        <div class="productContentPnl">
                          <a [routerLink]="goToProductDetailPage(productData)" [queryParams]="getQueryParamsForProduct(productData)"
                            class="productTitle" id="productName">{{productData.productName}}</a>
                          <div class="pricePnl">
                            <p class="discount hide" *ngIf="productData?.discountPercent && productData?.discountPercent > 0">{{productData?.discountPercent}}%
                              &nbsp;off</p>
                            <p class="mrp" *ngIf="productData?.mrp > productData?.salePrice && productData?.showSaving == 1 && productData?.discountPercent > 0">{{productData?.mrp
                              | currency:currType:'symbol': priceRoundOffInteger}}</p>
                            <p class="salePrice">{{productData?.salePrice | currency:currType:'symbol':
                              priceRoundOffInteger}}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="floatedLeft menuLink" *ngFor="let quickLink of quickLinks;">
              <a *ngIf="quickLink.pageURL.includes('http') && (quickLink.pageURL.includes('about-us') || quickLink.pageURL.includes('contact-us'))"
                [href]="quickLink.pageURL" class="mainLink" target="_blank">{{quickLink.linkName}}</a>
              <a *ngIf="!quickLink.pageURL.includes('http') && (quickLink.pageURL.includes('about-us') || quickLink.pageURL.includes('contact-us'))"
                [routerLink]="quickLink.pageURL" class="mainLink">{{quickLink.linkName}}</a>
            </li>
          </ul>
        </div>
        <div class="search-panel">
          <app-search></app-search>
        </div>
      </div>
    </div>
  </div>
</header>