import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router'
import { Constants } from '../../../common/constants/lsnetx.constants'
import { ProductDetailsService } from '../../../services/product-details.service'
import { ProductModel } from '../../../common/models/productModel'
import { CategoryListPageService } from '../../../services/categoryListPage.service'
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  itemList: Array<{ pathName: string, url: string }> = [];
  productDetail: ProductModel;
  categoryMap: Array<{ pathName: string, url: string }> = [];
  currentUrl: string;
  url: string;
  _productSubscription;
  _routeSubscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productDetailsService: ProductDetailsService
  ) { }

  ngOnInit() {
    var routeNavFlag = false;
    this._routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      routeNavFlag = true;
      this.currentUrl = data.url;
      if (data.url.includes("?")) {
        this.currentUrl = data.url.split("?")[0];
      }
      this.setItemList(this.currentUrl);
    });
    if(!routeNavFlag){
      this.currentUrl = this.router.url;
      if (this.router.url.includes("?")) {
        this.currentUrl = this.router.url.split("?")[0];
      }
      this.setItemList(this.currentUrl);
    }
  }

  ngOnDestroy() {
    if (this._productSubscription) {
      this._productSubscription.unsubscribe();
    }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  setItemList(data) {
    this.itemList = [];
    let ele: string
    let crumbs = data.split("/")

    crumbs.forEach(ele => {
      if (ele == "" || ele == Constants.WAR_NAME) {
        this.itemList.push({
          pathName: 'Home',
          url: ''
        })
      } else if (ele.includes('details')) {
        this._productSubscription = this.productDetailsService.productDetailObservable.subscribe((detail) => {
          this.itemList = [];
          this.itemList.push({
            pathName: 'Home',
            url: ''
          });
          this.productDetail = detail;
          if (this.productDetail.categoryMap != undefined) {
            this.categoryMap = []
            Object.keys(this.productDetail.categoryMap).forEach(key => {
              this.categoryMap.push({
                pathName: key,
                url: this.productDetail.categoryMap[key]
              })
            })
            if (this.categoryMap) {
              this.categoryMap.forEach(value => {
                this.itemList.push({
                  pathName: value.pathName,
                  url: value.url
                })
              })
            }
            if (this.productDetail != undefined) {
              this.itemList.push({
                pathName: this.productDetail.productName,
                url: ''
              })
            }
          }
        })
      } else if (ele.includes('collections')) {
        let crumbs = data.split("/")
        for (let i = 0; i < crumbs.length; i++) {
          let url: string = ''
          if (crumbs[i] != '' && crumbs[i] != Constants.WAR_NAME && crumbs[i] != 'collections') {
            let j = i
            while (j > 0) {
              if ((url != 'collections' && crumbs[j] != 'collections') && url != undefined)
                url = crumbs[j] + '/' + url
              j--
            }
            this.itemList.push({
              pathName: this.replaceHyphon(crumbs[i]),
              url: url
            })
          }
        }
      } else if (ele.includes('login')) {
        this.itemList.push({
          pathName: 'Login',
          url: ele
        })
      }
      else if (ele.includes('my-dashboard')) {
        this.itemList.push({
          pathName: 'My Dashboard',
          url: '/my-dashboard/profile'
        })
      }
      else {
        if (!(data.includes('details') || (data.includes('collections')))) {
          this.itemList.push({
            pathName: this.replaceHyphon(ele),
            url: ele
          })
        }
      }
    });
  }

  replaceHyphon(str: string): string {
    return str.split('-').join(' ')
  }

  goToRelativePage(url: string, index) {
    if (this.currentUrl.includes('/details')) {
      if (url == '') {
        this.router.navigate([url]);
      } else {
        if (this.categoryMap != undefined) {
          let route = '/' + 'collections' + '/' + this.categoryMap[index - 1].url
          this.router.navigate([route])
        } else {
          this.router.navigate([url]);
        }
      }
    } else if (this.currentUrl.includes('/collections')) {
      if (url == '') {
        this.router.navigate([url]);
      } else {
        let route = '/' + 'collections' + '/' + url;
        this.router.navigate([route]);
      }
    } else {
      this.router.navigate([url])
    }
  }

}
