import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomePageService } from '../../../../../services/home-page.service';
import { BannerModel } from '../../../../../common/models/bannerModel';
import { carouselElement } from '../../../../../LSNG/components/ls-carousel/carousel/carousel.component';
import { Router } from '@angular/router';
import { TemplateConstants } from '../../../../commonComponent/template-constants';
import { Constants } from '../../../../../common/constants/lsnetx.constants';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor(public homePageService: HomePageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  banners: Array<BannerModel> = [];
  images: Array<carouselElement> = undefined;
  defaultImages: Array<carouselElement> = new Array();
  bannerHeight = 100 + '%';
  defaultBanner = TemplateConstants.templateAssetsPath + "/images/banner/banner1.jpg";

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.bannerHeight = ((window.innerWidth * 400) / 1030)
    // }
    // if (this.bannerHeight > 400) {
    //   this.bannerHeight = 400
    // }
    // this.bannerHeight = this.bannerHeight + 'px'
    this.homePageService.bannersOffersObservable.subscribe((data) => {
      if (data && data.length > 0) {
        this.banners = [];
        let bannerOffers: Array<BannerModel> = data;
        bannerOffers.forEach((ele) => {
          if (ele.bannerType == Constants.BANNER_TYPE.BANNER) {
            this.banners.push(ele);
          }
        });
        if (this.banners.length > 0) {
          this.images = [];
          this.banners.forEach(element => {
            this.images.push({ imageUrl: element.imageName, title: element.altText })
          });
          // console.log("banners", this.banners, this.images);
        } else {
          this.setDefaultBanners();
        }
      } else {
        this.setDefaultBanners();
      }
    });
  }

  setDefaultBanners() {
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner1.jpg', title: 'defaultBanner1' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner2.jpg', title: 'defaultBanner2' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner3.jpg', title: 'defaultBanner3' })
    this.defaultImages.push({ imageUrl: TemplateConstants.templateAssetsPath + '/images/banner/banner4.jpg', title: 'defaultBanner4' })
    this.images = this.defaultImages;
  }

  doSomething(event) {
    let href: string = this.banners[event].imageHref
    if (href != undefined && href != '' && isPlatformBrowser(this.platformId)) {
      if (href.indexOf('collections') > -1) {
        this.router.navigate(['collections' + href.split('collections')[1]])
      } else {
        window.location.href = href;
      }
    }
  }

  bannerImgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultBanner;
  }

}
